/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-datetimepicker-calendar-header {
  background: #1e1ea0;
}

.spinner-backdrop,
.spinner-backdrop + .cdk-global-overlay-wrapper,
.spinner-panel {
  z-index: 1051;
}

/******************************
      Notification Popup
*******************************/

.cdk-global-overlay-wrapper {
  z-index: 1001 !important;
}

.mat-snack-bar-container {
  border-radius: 10px !important;
}

.mat-snack-bar-container .mat-simple-snackbar span {
  font-size: 17px !important;
  word-break: break-all;
}

.mat-snack-bar-container.danger {
  background: rgba(255, 0, 0, 1) !important;
}

.mat-snack-bar-container.warning {
  background: rgba(253, 178, 31, 1) !important;
}

.mat-snack-bar-container.success {
  background: rgba(60, 179, 113, 1) !important;
}

/******************************
          GENERIC
*******************************/
.hidden {
  display: none;
}

.horizontal-divider {
  border-left: 1px solid #CCCCCC;
}


/******************************
          Dialog Box
*******************************/

.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(2px);
}

.cdk-overlay-pane.dialog {
  max-height: 100vh;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1001;
}

.spinner-panel mat-dialog-container {
  padding: 0;
}

.spinner-panel .mat-dialog-content {
  min-width: 0 !important;
}

/******************************
         Toggle Switch
*******************************/
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #D1D7FF;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #495CEA;
}

/******************************
       WYSIWYG Editor
*******************************/

:not(.cke_widget_wrapper_easyimage):not(.cke_widget_wrapper_easyimage-align-right) > .easyimage-align-right {
  margin-right: 5px !important;
}

:not(.cke_widget_wrapper_easyimage):not(.cke_widget_wrapper_easyimage-align-left) > .easyimage-align-left {
  margin-left: 5px !important;
}

